<template>
<section class="chartjs">
    <div class="page-header align-items-start" style="align-items: flex-start !important">

        <h3 class="page-title d-flex align-items-center">
            Base de Empresas  <span class="mx-2"> |</span>
            <!-- <strong v-if="!total">Carregando ...</strong> -->
            <b-skeleton v-if="!total" class="d-inline-block " width="50px"></b-skeleton>

            <strong v-else>{{ total }}</strong>
        </h3>

         
        <b-form-checkbox  v-model="$store.getters.mudarVisualizacao" name="check-button" switch   @change="changeMudarVisualizacao">          
            <span >Mudar Visualização </span>      
        </b-form-checkbox>

        <!-- <button @click="changeTheme">add theme</button> -->


        <nav aria-label="breadcrumb">
            <ol class="breadcrumb pt-0 pb-0">
                <li class="breadcrumb-item">
                    <a href="javascript:void(0);" v-on:click="goBackVisaogeral">Visão Geral</a>
                </li>
                <!-- <li class="breadcrumb-item"><router-link to="/projeto/dashboard">Visão Geral</router-link></li> -->
                <li class="breadcrumb-item active" aria-current="page">
                    Base de Empresas
                </li>
            </ol>
        </nav>
    </div>

    <div class="row">
        <div :class="mudarVisualizacao ? 'col-lg-12 grid-margin stretch-card' : 'col-lg-6 grid-margin stretch-card' ">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Segmento</h4>
                    

                    <DoughnutChart descricaoAba="base_empresas" tipo="segmentos" />
                    <!-- <doughnutChartjs :height="400" v-if="mudarVisualizacao"></doughnutChartjs> -->

                </div>
            </div>
        </div>
        <div :class="mudarVisualizacao ? 'col-lg-12 grid-margin stretch-card' : 'col-lg-6 grid-margin stretch-card' ">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Funcionários</h4>
                    <DoughnutChart descricaoAba="base_empresas" tipo="funcionarios" />
                    <!-- <doughnutChartjs :height="400"></doughnutChartjs> -->

                </div>
            </div>
        </div>

        <!-- ------------------- -->

        <div :class="mudarVisualizacao ? 'col-lg-12 grid-margin stretch-card' : 'col-lg-6 grid-margin stretch-card' ">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Estados</h4>

                    <DoughnutChart descricaoAba="base_empresas" tipo="estados" />
                </div>
            </div>
        </div>
        <div :class="mudarVisualizacao ? 'col-lg-12 grid-margin stretch-card' : 'col-lg-6 grid-margin stretch-card' ">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Faturamento</h4>

                    <DoughnutChart descricaoAba="base_empresas" tipo="faturamento" />
                </div>
            </div>
        </div>

    </div>

    <!-- --------OTHER VIEW-     LADO A LADO-------- -->

    <!-- <div class="row" v-show="!mudarVisualizacao">
        <div class="col-lg-6 grid-margin stretch-card ">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Segmento</h4>

                    <AmchartsAside descricaoAba="base_empresas" tipo="segmentos" />

                </div>
            </div>
        </div>
        <div class="col-lg-6 grid-margin stretch-card ">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Funcionários</h4>
                    <AmchartsAside descricaoAba="base_empresas" tipo="funcionarios" />

                </div>
            </div>
        </div>


        <div class="col-lg-6 grid-margin stretch-card ">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Estados</h4>

                    <AmchartsAside descricaoAba="base_empresas" tipo="estados" />
                </div>
            </div>
        </div>
        <div class="col-lg-6 grid-margin stretch-card ">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Faturamento</h4>

                    <AmchartsAside descricaoAba="base_empresas" tipo="faturamento" />
                </div>
            </div>
        </div>
    </div> -->

</section>
</template>

<script>
import DoughnutChart from "./Amcharts.vue";
// import AmchartsAside from "./AmchartsAside.vue";
//  import doughnutChartjs from './doughnutChart.vue'
//import doughnutChartjs from '../../../components'
import {
    mapState,
    mapMutations,
    //  mapGetters
} from "vuex";

import projetoService from "../../../services/projeto.service";

export default {
    name: "chartjs",
    components: {
        DoughnutChart,
        // AmchartsAside
        //  doughnutChartjs
    },

    data() {
        return {
            colunas: "col-lg-6 grid-margin stretch-card",
            total: undefined,
        }
    },

    methods: {
        ...mapMutations(["changeMudarVisualizacao"]),

        goBackVisaogeral() {
            document.getElementById('visaoGeralButton___BV_tab_button__').click()
        },
        getInfDashboard() {
            projetoService.getInfDashboard(this.$route.params.id)
                .then((res) => {
                    //console.log(res.data);
                    this.total = res.data.base_empresas.total.toLocaleString("pt-BR")
                })
                .catch((erro) => {
                    console.error("erro ao obter informaçoes do projeto  " + erro);
                })
        }

    },

    mounted() {
        this.getInfDashboard()
       // document.getElementById("id-66-title").remove()

       


        // console.log(this.mudarVisualizacao);

    },
    computed: {
        ...mapState(["mudarVisualizacao"])
    },
    watch:{
        
    }

};
</script>

<style lang="scss" >

.custom-switch .custom-control-label::after{
    top: calc(0.25rem + -2px);    
}
.chartjs .custom-control-label span{
    cursor: pointer;
    position: relative;
    top: -2px;
}

.card{
    /* background: white !important; */
}
</style>
