<template>
<div class="hello" ref="chartdiv" id="chartdiv">

    <!-- tela de loadding -->
    <div class="dot-opacity-loader" v-if="!descricao">
        <span></span>
        <span></span>
        <span></span>
    </div>

</div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);
import projetoService from "../../../services/projeto.service";

export default {
    name: "HelloWorld",

    props: {
        visao: String,
    },

    data() {
        return {
            descricao: undefined
        };
    },
    methods: {

        mountGrafic() {

            // let chart = am4core.create('chartdivs', am4charts.XYChart)

            let chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart);

            // chart.colors.step = 2;
            chart.colors.step = 2;

            chart.colors.list = [
                am4core.color("#67b7dc"),
                am4core.color(""),

                am4core.color("#6771dc"),
                am4core.color(""),
                am4core.color("#da5775"),
                am4core.color("")
            ];

            chart.legend = new am4charts.Legend();
            chart.legend.position = "top";
            chart.legend.paddingBottom = 20;
            chart.legend.labels.template.maxWidth = 95;

            let xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
            xAxis.dataFields.category = "category";
            xAxis.renderer.cellStartLocation = 0.1;
            xAxis.renderer.cellEndLocation = 0.9;
            xAxis.renderer.grid.template.location = 0;

            let yAxis = chart.yAxes.push(new am4charts.ValueAxis());
            yAxis.min = 0;

            function createSeries(value, name) {
                let series = chart.series.push(new am4charts.ColumnSeries());
                series.dataFields.valueY = value;
                series.dataFields.categoryX = "category";
                series.name = name;

                series.events.on("hidden", arrangeColumns);
                series.events.on("shown", arrangeColumns);

                let bullet = series.bullets.push(new am4charts.LabelBullet());
                bullet.interactionsEnabled = false;
                bullet.dy = 30;
                bullet.label.text = "{valueY}";
                bullet.label.fill = am4core.color("#ffffff");

                return series;
            }

            chart.data = this.descricao.map((item) => {
                return {
                    category: item.label,
                    first: item.empresas_qualificadas,
                    second: item.oportunidades,
                    third: item.recusas,
                };
            });

            /*

              chart.data = [
                {

                      category: 'Março',
                      first: 40,
                      second: 55,
                      third: 60
                  },
                  {
                      category: 'Abril',
                      first: 30,
                      second: 78,
                      third: 69
                  },
                  {
                      category: 'Maio',
                      first: 27,
                      second: 40,
                      third: 45
                  },
                  {
                      category: 'Junho',
                      first: 50,
                      second: 33,
                      third: 22
                  },
                  {
                      category: 'Julho',
                      first: 62,
                      second: 33,
                      third: 22
                  },
                  {
                      category: 'Agosto',
                      first: 24,
                      second: 13,
                      third: 57
                  },
              ]

              */

            createSeries("first", "Qualificado");
            createSeries("second", "Oportunidades");
            createSeries("third", "Recusas");

            function arrangeColumns() {
                let series = chart.series.getIndex(0);

                let w =
                    1 -
                    xAxis.renderer.cellStartLocation -
                    (1 - xAxis.renderer.cellEndLocation);
                if (series.dataItems.length > 1) {
                    let x0 = xAxis.getX(series.dataItems.getIndex(0), "categoryX");
                    let x1 = xAxis.getX(series.dataItems.getIndex(1), "categoryX");
                    let delta = ((x1 - x0) / chart.series.length) * w;
                    if (am4core.isNumber(delta)) {
                        let middle = chart.series.length / 2;

                        let newIndex = 0;
                        chart.series.each(function (series) {
                            if (!series.isHidden && !series.isHiding) {
                                series.dummyData = newIndex;
                                newIndex++;
                            } else {
                                series.dummyData = chart.series.indexOf(series);
                            }
                        });
                        let visibleCount = newIndex;
                        let newMiddle = visibleCount / 2;

                        chart.series.each(function (series) {
                            let trueIndex = chart.series.indexOf(series);
                            let newIndex = series.dummyData;

                            let dx = (newIndex - trueIndex + middle - newMiddle) * delta;

                            series.animate({
                                    property: "dx",
                                    to: dx,
                                },
                                series.interpolationDuration,
                                series.interpolationEasing
                            );
                            series.bulletsContainer.animate({
                                    property: "dx",
                                    to: dx,
                                },
                                series.interpolationDuration,
                                series.interpolationEasing
                            );
                        });
                    }
                }
            }
            this.chart = chart;

        },

        loadingData() {
            projetoService.getInfDashboardFilterByVision(this.$route.params.id, this.visao)
                .then(res => {
                    //  console.log(res);
                    this.descricao = res.data.metas.progresso
                    this.mountGrafic()

                }).catch((erro) => {
                    console.error("erro ao obter informaçoes do projeto  " + erro);
                })
        },

    },

    mounted() {
        this.loadingData()

    },

    beforeDestroy() {
        if (this.chart) {
            this.chart.dispose();
        }
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.hello {
    display: flex;
    align-items: center;

    width: 100%;
    height: 500px;
}
</style>
