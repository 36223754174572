<template>
<section class="widget">


    <span @click="handlerCalendar" class="select--periodo  ">Selecionar Periodo
        <i class="fa fa-calendar ml-2" v-show="!showCalendar"></i>
        <i class="fa fa-window-close ml-2" v-show="showCalendar"></i>
    </span>

    <div v-show="showCalendar" style="margin-top: -14px;" class="mb-3">
        <v-date-picker v-model="range" is-range >
            <template v-slot="{ inputValue, inputEvents }">
                <div class="d-flex justify-center items-center my-2">
                    <input :value="inputValue.start" v-on="inputEvents.start" is-dark class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300"   />
                    <svg class="w-4 h-4 mx-2 my-svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                    </svg>
                    <input :value="inputValue.end" v-on="inputEvents.end" class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300"  />
                    <button class="btn btn-primary ml-4" @click="pesquisar">
                        <template v-if="loading">
                            <b-spinner small type="grow"></b-spinner>
                            <span class="ml-2">Pesquisando... </span>
                        </template>
                        <template v-else> Pesquisar </template>
                    </button>

                    <button v-show="!showAllPeriod" class="btn btn-outline-primary ml-4" @click="removeFilters">
                        Remover Filtros
                        <!-- <template v-if="loadingRemove">
                            <b-spinner small type="grow"></b-spinner>
                            <span class="ml-2">Removendo... </span>
                        </template>
                      <template v-else> Remover Filtros </template> -->
                    </button>
                </div>
            </template>
        </v-date-picker>
    </div>


    

    <!-- <v-date-picker v-model="range" is-range class="my-calendar" is-dark v-show="showCalendar"  /> -->

    <h4 class="mb-3 page-title " v-show="!showAllPeriod">Mostrando resultado para data de: <span class="text-primary ml-2">{{dateStart}}</span> <span class="mx-2">até</span> <span class="text-primary">{{endData}}</span> </h4>
    <h4 class="mb-3 page-title " v-show="showAllPeriod">Mostrando resultado para <span class="text-primary  ml-1 fst-italic">Todo o Período</span> </h4>

    <div class="row">
        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 grid-margin stretch-card" v-on:click="handleRota(2)">
            <div class="card card-statistics">
                <div class="card-body pb-0">
                    <p class="text-muted">Base de Empresas</p>
                    <div class="d-flex align-items-center">

                        <b-skeleton class="d-inline-block " width="100px" v-if="!infoTotal.baseEmpresas"></b-skeleton>
                        <h4 class="font-weight-semibold white-mode__black-bold" v-else>{{infoTotal.baseEmpresas}}</h4>
                        <h6 class="text-success font-weight-semibold white-mode__black-bold ml-2" v-if="resultAll.base_empresas.diff> 0">+{{resultAll.base_empresas.diff}}</h6>
                    </div>
                    <small class="">Esta foi uma ótima atualização.</small>
                </div>
                <totalInvoice :height="40"></totalInvoice>
            </div>
        </div>

        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 grid-margin stretch-card" v-on:click="handleRota(3)">
            <div class="card card-statistics">
                <div class="card-body pb-0">
                    <p class="text-muted">Base de Contatos</p>
                    <div class="d-flex align-items-center">
                        <b-skeleton class="d-inline-block " width="100px" v-if="!infoTotal.baseContatos"></b-skeleton>

                        <h4 class="font-weight-semibold white-mode__black-bold" v-else>{{infoTotal.baseContatos}}</h4>
                        <h6 class="text-success font-weight-semibold white-mode__black-bold ml-2" v-if="resultAll.base_contatos.diff > 0">+{{resultAll.base_contatos.diff}}</h6>
                    </div>
                    <small class="">Ver detalhes</small>
                </div>
                <totalExpence :height="40"></totalExpence>
            </div>
        </div>
        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 grid-margin stretch-card" v-on:click="handleRota(4)">
            <div class="card card-statistics">
                <div class="card-body pb-0">
                    <p class="text-muted">Empresas Qualificadas</p>
                    <div class="d-flex align-items-center">
                        <b-skeleton class="d-inline-block " width="100px" v-if="!infoTotal.empresasQualificadas"></b-skeleton>

                        <h4 class="font-weight-semibold white-mode__black-bold" v-else>{{infoTotal.empresasQualificadas}}</h4>
                        <h6 class="text-success font-weight-semibold white-mode__black-bold ml-2" v-if="resultAll.empresas_qualificadas.diff > 0">+{{resultAll.empresas_qualificadas.diff}}</h6>
                    </div>
                    <small class="">Ver histórico</small>
                </div>
                <unPaidInvoices :height="40"></unPaidInvoices>
            </div>
        </div>
        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 grid-margin stretch-card" v-on:click="handleRota(5)">
            <div class="card card-statistics">
                <div class="card-body pb-0">
                    <p class="text-muted">Empresas Não Qualificadas</p>
                    <div class="d-flex align-items-center">
                        <b-skeleton class="d-inline-block " width="100px" v-if="!infoTotal.empresasNaoQualificadas"></b-skeleton>

                        
                        <h4 class="font-weight-semibold white-mode__black-bold" v-else>{{infoTotal.empresasNaoQualificadas}}</h4>
                        <h6 class="text-danger font-weight-semibold white-mode__black-bold ml-2" v-if="resultAll.empresas_nao_qualificadas.diff > 0">+{{resultAll.empresas_nao_qualificadas.diff}}</h6>
                    </div>
                    <small class="">Ver detalhes</small>
                </div>
                <amountDue :height="40"></amountDue>
            </div>
        </div>

        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 grid-margin stretch-card" v-on:click="handleRota(6)">
            <div class="card card-statistics">
                <div class="card-body pb-0">
                    <p class="text-muted">Total de Oportunidades</p>
                    <div class="d-flex align-items-center">
                        <b-skeleton class="d-inline-block " width="100px" v-if="!infoTotal.oportunidades"></b-skeleton>

                        <h4 class="font-weight-semibold white-mode__black-bold" v-else>{{infoTotal.oportunidades}}</h4>
                        <h6 class="text-success font-weight-semibold white-mode__black-bold ml-2" v-if="resultAll.oportunidades.diff > 0">+{{resultAll.oportunidades.diff}}</h6>
                    </div>
                    <small class="">Atualização</small>
                </div>
                <totalInvoice :height="40"></totalInvoice>
            </div>
        </div>
        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 grid-margin stretch-card" v-on:click="handleRota(7)">
            <div class="card card-statistics">
                <div class="card-body pb-0">
                    <p class="text-muted">Total de Recusas</p>
                    <div class="d-flex align-items-center">
                        <b-skeleton class="d-inline-block " width="100px" v-if="!infoTotal.recusas"></b-skeleton>

                        <h4 class="font-weight-semibold white-mode__black-bold" v-else>{{infoTotal.recusas}}</h4>
                        <h6 class="text-danger font-weight-semibold white-mode__black-bold ml-2" v-if="resultAll.recusas.diff > 0">+{{resultAll.recusas.diff}}</h6>
                    </div>
                    <small class="">Ver detalhes</small>
                </div>
                <totalExpence :height="40"></totalExpence>
            </div>
        </div>
        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 grid-margin stretch-card" v-on:click="handleRota(8)">
            <div class="card card-statistics">
                <div class="card-body pb-0">
                    <p class="text-muted">Meta Geral do Projeto</p>
                    <div class="d-flex align-items-center">
                        <b-skeleton class="d-inline-block " width="100px" v-if="!infoTotal.metas"></b-skeleton>

                        <h4 class="font-weight-semibold white-mode__black-bold">{{infoTotal.metas}}</h4>
                        <!-- <h6 class="text-success font-weight-semibold white-mode__black-bold ml-2" v-if="resultAll.metas.meta_geral > 0">+{{resultAll.metas.meta_geral}}</h6> -->
                    </div>
                    <small class="">Ver detalhes</small>
                </div>
                <unPaidInvoices :height="40"></unPaidInvoices>
            </div>
        </div>
        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 grid-margin stretch-card">
            <div class="card card-statistics">
                <div class="card-body pb-0">
                    <p class="text-muted">Oportunidades Restantes</p>
                    <div class="d-flex align-items-center">
                        <b-skeleton class="d-inline-block " width="100px" v-if="!resultAll.metas.restantes"></b-skeleton>


                        <h4 class="font-weight-semibold white-mode__black-bold">{{resultAll.metas.restantes}}</h4>
                        <!-- <h6 class="text-success font-weight-semibold ml-2" v-if="resultAll.metas.restantes > 0">{{resultAll.metas.restantes}}</h6> -->
                    </div>
                    <small class="">Ver detalhes</small>
                </div>
                <amountDue :height="40"></amountDue>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import totalInvoice from "../../../components/charts/widgets/totalInvoice"; //

import totalExpence from "../../../components/charts/widgets/totalExpence"; //

import unPaidInvoices from "../../../components/charts/widgets/unPaidInvoices"; //
import amountDue from "../../../components/charts/widgets/amountDue"; //

import projetoService from "../../../services/projeto.service"; //

export default {
    name: "widget",
    components: {

        totalInvoice,
        totalExpence,
        unPaidInvoices,
        amountDue,

    },

    data() {
        return {
            range: {

                start: new Date(),
                end: new Date(),
            },
            infoTotal: {
                baseEmpresas: undefined,
                baseContatos: undefined,
                empresasQualificadas: undefined,
                empresasNaoQualificadas: undefined,
                oportunidades: undefined,
                recusas: undefined,
                metas: undefined

            },
            showCalendar: false,
            showAllPeriod: true,
            loading: false,
            resultAll: {
                // base_empresas:{diff: ""}
                base_empresas: Object,
                base_contatos: Object,
                empresas_qualificadas: Object,
                empresas_nao_qualificadas: Object,
                oportunidades: Object,
                recusas: Object,
                metas: Object



            }

            // tudo.base_empresas.diff
        }
    },

    methods: {
        handleRota: function (id) {
            document.querySelector(`.col-auto ul li:nth-child(${id}) a`).click();
        },

        handlerCalendar() {
            this.showCalendar = !this.showCalendar
        },

        pesquisar() {            
            this.loading = true;
            let inicio = this.dateStart
                .split("/")
                .reverse()
                .join("-");
            let fim = this.endData
                .split("/")
                .reverse()
                .join("-");

            this.infoTotal = {
                baseEmpresas: undefined,
                baseContatos: undefined,
                empresasQualificadas: undefined,
                empresasNaoQualificadas: undefined,
                oportunidades: undefined,
                recusas: undefined,
                metas: undefined
            }
           

            projetoService
                .getInfDashboardFilter(this.$route.params.id, inicio, fim)
                .then((res) => {
                    // console.log(res);
                    //this.dadosGrafico = res.data.empresas_qualificadas;
                    //console.log(this.dadosGrafico );



                    this.infoTotal.baseEmpresas = res.data.base_empresas.total.toLocaleString("pt-BR")
                    this.infoTotal.baseContatos = res.data.base_contatos.total.toLocaleString("pt-BR")
                    this.infoTotal.empresasQualificadas = res.data.empresas_qualificadas.total.toLocaleString("pt-BR")
                    this.infoTotal.empresasNaoQualificadas = res.data.empresas_nao_qualificadas.total.toLocaleString("pt-BR")
                    this.infoTotal.oportunidades = res.data.oportunidades.total.toLocaleString("pt-BR")
                    this.infoTotal.recusas = res.data.recusas.total.toLocaleString("pt-BR")
                    this.infoTotal.metas = res.data.metas.meta_geral.toLocaleString("pt-BR")






                    this.showAllPeriod = false;
                    // this.total = res.data.empresas_qualificadas.total.toLocaleString(
                    //     "pt-BR"
                    // );
                    //this.carregarGrafico();
                    this.loading = false;
                })
                .catch((erro) => {
                    console.error(
                        "erro ao obter informaçoes filtrada do projeto  " + erro
                    );
                    this.loading = false;
                });
        },
        removeFilters() {

           
            this.infoTotal = {
                baseEmpresas: undefined,
                baseContatos: undefined,
                empresasQualificadas: undefined,
                empresasNaoQualificadas: undefined,
                oportunidades: undefined,
                recusas: undefined,
                metas: undefined
            }
            this.getInfDashboard();           
            this.showCalendar = false


            
        },

        getInfDashboard(){
            projetoService.getInfDashboard(this.$route.params.id)
            .then((res) => {
               // console.log(res.data);
                this.showAllPeriod = true;

                this.infoTotal.baseEmpresas = res.data.base_empresas.total.toLocaleString("pt-BR")


                
                this.infoTotal.baseContatos = res.data.base_contatos.total.toLocaleString("pt-BR")
                this.infoTotal.empresasQualificadas = res.data.empresas_qualificadas.total.toLocaleString("pt-BR")
                this.infoTotal.empresasNaoQualificadas = res.data.empresas_nao_qualificadas.total.toLocaleString("pt-BR")
                this.infoTotal.oportunidades = res.data.oportunidades.total.toLocaleString("pt-BR")
                this.infoTotal.recusas = res.data.recusas.total.toLocaleString("pt-BR")
                this.infoTotal.metas = res.data.metas.meta_geral.toLocaleString("pt-BR")
                //console.log(this.infoTotal.base_empresas.total)

                

                this.resultAll = res.data

            })
            .catch((erro) => {
                console.error(erro);
            })


        }







    },
    mounted() {
        this.getInfDashboard()
        
    },

    computed: {
        dateStart: function () {
            let dia = this.range.start.getDate()
            let mes = this.range.start.getMonth() + 1
            let ano = this.range.start.getFullYear()
            if (dia <= 9) {
                dia = "0" + dia
            }
            if (mes < 9) {
                mes = "0" + mes
            }
            return `${dia}/${mes}/${ano}`
        },
        endData: function () {
            let dia = this.range.end.getDate()
            let mes = this.range.end.getMonth() + 1
            let ano = this.range.end.getFullYear()
            if (dia <= 9) {
                dia = "0" + dia
            }
            if (mes < 9) {
                mes = "0" + mes
            }

            return `${dia}/${mes}/${ano}`

        },

        

    }
};
</script>

<style lang="scss" scoped>
.widget {
    flex: 1;
}

.widget .row>div {
    cursor: pointer;
    transition: 0.2s;

    &:hover {
        margin-top: -10px;
    }
}

.select--periodo {
    max-width: 170px;
    cursor: pointer;
    padding-bottom: 14px;

    display: inline-block;
    color: #6c7293;
    transition: .1s;

}

.my-svg {
    width: 30px;
}
</style>
