<template>
  <section class="col-lg-12 grid-margin stretch-card this__map">
    <div class="card ">
      <div class="card-body">
        <!-- <h3>Total de Opotunidades</h3> -->

        <!-- tela de loadding -->
        <div  class="dot-opacity-loader" v-if="loading">
            <span></span>
            <span></span>
            <span></span>
        </div>

        <div class="area--mapa  " v-else>
          <GChart
            :settings="{ packages: ['geochart'] }"
            type="GeoChart"
            :data="chartData"
            :options="chartOptions"
            class="mapaItem1 total__opottunidade--white"
          />

          


        </div>

        

      </div>
    </div>
  </section>
</template>

<script>
// import AmchartsMapa from './AmchartsMapa.vue'
 import { GChart } from "vue-google-charts";
import { mapState, mapGetters } from "vuex";
import MedicoService from "@/services/doktor/medico.service";

export default {
  components: {
    // AmchartsMapa,
     GChart,
  },
  data() {
    return {
      chartData: [["Estado", "Médicos"]],

      chartOptions: {
        region: "BR",
        resolution: "provinces",
        colorAxis: {
          colors: ["#acb2b9", "#2f3f4f"],
        },
        //  backgroundColor: "red",
        backgroundColor: "#191c24",
        keepAspectRatio: true,
      },
      loading: true
    };
  },

  computed: {
    ...mapState(["medicoFiltros"]),
    ...mapGetters(["medicoFiltrosQuery"]),
  },
  mounted() {
    this.search();
    //console.log(this.chartData);
  },
  watch: {
    // Atualizar contador somente se houver mudancas nos filtros
    medicoFiltrosQuery: function() {
      this.search();
    },
  },
  methods: {
    search() {
      let params = {
        ...this.medicoFiltrosQuery,
      };
      this.loading = true;
      //estados listrados somente como exemplo => apagar
      this.chartData.push(["Amazonas", 874]);
      this.chartData.push(["Sao Paulo", 100]);
      this.chartData.push(["Bahia", 254]);
      this.chartData.push(["Parana", 11]);
      this.chartData.push(["Mato grosso", 547]);
      this.chartData.push(["Mato grosso do sul", 111]);
      this.chartData.push(["Rondonia", 11]);
      this.chartData.push(["Rio grande do Sul", 357]);

      MedicoService.total(params).then((response) => {
        this.totalRows = response.data.total;
        this.chartData = [["Estado", "Médicos"]];
        this.totalUfs = response.data.ufs;
        response.data.ufs.forEach((uf) => {
          this.chartData.push([uf.estado.nome, uf.uf_count]);
        });

        this.loading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
section {
  width: 100%;
  display: flex;
  justify-content: center;
  background: #191c24;
  /* background: #b3099c; */
  border-radius: 0.25rem;
}

.area--mapa {
  /* height: 100%; */
  /* width: 50%; */
  display: flex;
  justify-content: center;

  .mapaItem1 {
    /* height: 100%; */
  }
}
</style>
