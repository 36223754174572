<template>
  <div class="hello" ref="chartdiv">
    <div
      class="
        col-md-12 col-sm-6
        grid-margin
        stretch-card
        h-100
        d-flex
        align-items-center
      "
    >
      <!-- tela de loadding -->
      <div class="dot-opacity-loader">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import dark from "@amcharts/amcharts4/themes/dark";

import projetoService from "../../../services/projeto.service";

am4core.useTheme(am4themes_animated);
am4core.useTheme(dark);

export default {
  props: {
    descricao: {
      type: Array,
    },
    descricaoAba: {},
    tipo: {},
  },
  name: "",
  data() {
    return {
      descri: [],
    };
  },

  methods: {
    carregarGrafico() {
      let chart = am4core.create(this.$refs.chartdiv, am4charts.PieChart3D);
      chart.legend = new am4charts.Legend();
      chart.legend.useDefaultMarker = true;
      //  chart.paddingRight = 120
      //  chart.paddingLeft = 60

      chart.data = this.descri.map((item) => {
        //console.log(item);
        return {
          segmento: item.label,
          percentual: item.quantidade,
        };
      });

      // nao apagar, serve de referencia para montar o array

      /*
                    chart.data = [
                      {
                        segmento: "Sociedades médicas",
                        percentual: 501.9,
                      },
                      {
                        segmento: "Indústria Farmacêutica",
                        percentual: 301.9,
                      },

                    ];
                    */

      chart.innerRadius = 80;

      chart.legend.position = "right";

      chart.responsive.enabled = true;
      chart.responsive.rules.push({
        relevant: function (target) {
          if (target.pixelWidth <= 600) {
            return true;
          }
          return false;
        },
        state: function (target, stateId) {
          if (target instanceof am4charts.PieSeries) {
            var state = target.states.create(stateId);

            var labelState = target.labels.template.states.create(stateId);
            labelState.properties.disabled = true;

            var tickState = target.ticks.template.states.create(stateId);
            tickState.properties.disabled = true;
            return state;
          }

          return null;
        },
      });

      let series = chart.series.push(new am4charts.PieSeries3D());
      series.dataFields.value = "percentual";
      series.dataFields.category = "segmento";



      this.chart = chart;
    },

   
  },

  mounted () {
    projetoService
      .getInfDashboard(this.$route.params.id)
      .then((res) => {
        //console.log(res.data[this.descricaoAba]);
        //console.log(res.data);

        //apos o a api estiver completa, remover essa verificaçao if
        if (this.descricao) {
          this.descri = this.descricao;
        } else {
          this.descri = res.data[this.descricaoAba][this.tipo];
        }

        this.carregarGrafico();
      })
      .catch((erro) => {
        console.log("Erro ao obter Informações da dashboard " + erro);
      });




  },
  computed: {
    ...mapState(["showWhiteMode"]),
  },

  watch: {
   
  },

  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.hello {
  /* width: 100%; */
  /* min-height: 370px; */
  /* min-height: 500px; */
  /* min-height: 350px; */
  /* min-height: 500px; */
  height: 400px;
  /* height: 800px; */
  /* height: auto; */
  width: 100%;
  min-width: 400px;
  /* height: auto; */
  /* background: red; */
}
</style>
