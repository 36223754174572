<template>
<section class="chartjs">
    <div class="page-header align-items-start mb-2" style="align-items: flex-start !important">
        <h3 class="page-title d-flex align-items-center">
            Empresas Qualificadas <span class="mx-2"> |</span> 
            <!-- <strong v-if="!total">Carregando ...</strong> -->
            <b-skeleton v-if="!total" class="d-inline-block " width="50px"></b-skeleton>

            <strong v-else>{{ total }}</strong>
        </h3>

 <b-form-checkbox  v-model="$store.getters.mudarVisualizacao" name="check-button" switch   @change="changeMudarVisualizacao">          
            <span >Mudar Visualização </span>      
        </b-form-checkbox>

        <nav aria-label="breadcrumb">
            <ol class="breadcrumb pt-0 pb-0">
                <li class="breadcrumb-item">
                    <a href="javascript:void(0); " v-on:click="goBackVisaogeral">Visão Geral</a>
                </li>
                <!-- <li class="breadcrumb-item"><router-link to="/projeto/dashboard">Visão Geral</router-link></li> -->
                <li class="breadcrumb-item active" aria-current="page">
                    Empresas Qualificadas
                </li>
            </ol>
        </nav>
    </div>

    <!-- <button @click="getInfDashboard">teste</button> -->

    <span @click="handlerCalendar" class="select--periodo " >Selecionar Periodo
        <i class="fa fa-calendar ml-2" v-show="!showCalendar"></i>
        <i class="fa fa-window-close ml-2" v-show="showCalendar"></i>
    </span>

    <div v-show="showCalendar" style="margin-top: -14px;" class="mb-3">
        <v-date-picker v-model="range" is-range >
            <template v-slot="{ inputValue, inputEvents }">
                <div class="d-flex justify-center items-center my-2">
                    <input :value="inputValue.start" v-on="inputEvents.start" is-dark class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300"  />
                    <svg class="w-4 h-4 mx-2 my-svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                    </svg>
                    <input :value="inputValue.end" v-on="inputEvents.end" class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300"  />
                    <button class="btn btn-primary ml-4" @click="pesquisar">
                        <template v-if="loading">
                            <b-spinner small type="grow"></b-spinner>
                            <span class="ml-2">Pesquisando... </span>
                        </template>
                        <template v-else> Pesquisar </template>
                    </button>

                    <button v-show="!showAllPeriod" class="btn btn-outline-primary ml-4" @click="removeFilters">
                        Remover Filtros
                        <!-- <template v-if="loadingRemove">
                            <b-spinner small type="grow"></b-spinner>
                            <span class="ml-2">Removendo... </span>
                        </template>
                      <template v-else> Remover Filtros </template> -->
                    </button>
                </div>
            </template>
        </v-date-picker>
    </div>

    <!-- <v-date-picker v-model="range" is-range class="my-calendar" is-dark v-show="showCalendar"  /> -->

    <h4 class="mb-3 page-title" v-show="!showAllPeriod">
        Mostrando resultado para data de:
        <span class="text-primary ml-2">{{ dateStart }}</span>
        <span class="mx-2">até</span>
        <span class="text-primary">{{ endData }}</span>
    </h4>
    <h4 class="mb-3 page-title" v-show="showAllPeriod">
        Mostrando resultado para
        <span class="text-primary  ml-1 fst-italic">Todo o Período</span>
    </h4>
    <div class="row">

        <div :class="mudarVisualizacao ? 'col-lg-12 grid-margin stretch-card' : 'col-lg-6 grid-margin stretch-card' ">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Segmento</h4>

                    <!-- <DoughnutChart descricaoAba="empresas_qualificadas" tipo="segmentos" /> -->

                    <div class="spaceGrafico" ref="segmento">
                        <div class="col-md-12 col-sm-6 grid-margin stretch-card  h-100 d-flex align-items-center  ">
                            <!-- tela de loadding -->
                            <div class="dot-opacity-loader">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    <!-- ------------------- -->

        <div :class="mudarVisualizacao ? 'col-lg-12 grid-margin stretch-card' : 'col-lg-6 grid-margin stretch-card' ">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Funcionários</h4>

                    <!-- <DoughnutChart descricaoAba="empresas_qualificadas" tipo="funcionarios" /> -->

                    <div class="spaceGrafico" ref="funcionarios">
                        <div class="col-md-12 col-sm-6 grid-margin stretch-card  h-100 d-flex align-items-center  ">
                            <!-- tela de loadding -->
                            <div class="dot-opacity-loader">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div :class="mudarVisualizacao ? 'col-lg-12 grid-margin stretch-card' : 'col-lg-6 grid-margin stretch-card' ">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Estados</h4>

                    <!-- <DoughnutChart descricaoAba="empresas_qualificadas" tipo="estados" /> -->

                    <div class="spaceGrafico" ref="estados">
                        <div class="col-md-12 col-sm-6 grid-margin stretch-card  h-100 d-flex align-items-center  ">
                            <!-- tela de loadding -->
                            <div class="dot-opacity-loader">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div :class="mudarVisualizacao ? 'col-lg-12 grid-margin stretch-card' : 'col-lg-6 grid-margin stretch-card' ">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Faturamento</h4>

                    <!-- <DoughnutChart descricaoAba="empresas_qualificadas" tipo="faturamento" /> -->

                    <div class="spaceGrafico" ref="faturamento">
                        <div class="col-md-12 col-sm-6 grid-margin stretch-card  h-100 d-flex align-items-center  ">
                            <!-- tela de loadding -->
                            <div class="dot-opacity-loader">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
// import DoughnutChart from "./Amcharts.vue";
//  import PieChartAmCharts from "./DoughnutChartAmcharts.vue";
import projetoService from "../../../services/projeto.service";
import { mapState, 
mapMutations

//  mapGetters
 } from "vuex";

// ------------abaixo arquivos import do grafico
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import dark from "@amcharts/amcharts4/themes/dark";

am4core.useTheme(am4themes_animated);
am4core.useTheme(dark);
am4core.options.autoDispose = true;

export default {
    name: "chartjs",
    components: {
        // DoughnutChart,
        //  PieChartAmCharts
    },

    data() {
        return {
            range: {
                start: new Date(),
                end: new Date(),
            },
            showCalendar: false,
            showAllPeriod: true,
            total: undefined,
            loading: false,
            // loadingRemove: false,

            dadosGrafico: [],
        };
    },

    computed: {
        ...mapState(["mudarVisualizacao"]),

        dateStart: function () {
            let dia = this.range.start.getDate();
            let mes = this.range.start.getMonth() + 1;
            let ano = this.range.start.getFullYear();
            if (dia <= 9) {
                dia = "0" + dia;
            }
            if (mes < 9) {
                mes = "0" + mes;
            }
            return `${dia}/${mes}/${ano}`;
        },
        endData: function () {
            let dia = this.range.end.getDate();
            let mes = this.range.end.getMonth() + 1;
            let ano = this.range.end.getFullYear();
            if (dia <= 9) {
                dia = "0" + dia;
            }
            if (mes < 9) {
                mes = "0" + mes;
            }

            return `${dia}/${mes}/${ano}`;
        },
    },

    methods: {
        ...mapMutations(["changeMudarVisualizacao"]),
        goBackVisaogeral() {
            document.getElementById("visaoGeralButton___BV_tab_button__").click();
        },
        handlerCalendar() {
            this.showCalendar = !this.showCalendar;
        },

        getInfDashboard() {
            // this.showAllPeriod = true
            this.showCalendar = false;

            // if (this.chart) {
            //     this.chart.dispose();
            // }

            projetoService
                .getInfDashboard(this.$route.params.id)
                .then((res) => {
                    // console.log(res.data);
                    this.showAllPeriod = true;

                    this.dadosGrafico = res.data.empresas_qualificadas;
                    //   console.log(this.dadosGrafico);

                    this.carregarGrafico();

                    this.total = res.data.empresas_qualificadas.total.toLocaleString(
                        "pt-BR"
                    );
                })
                .catch((erro) => {
                    console.error("erro ao obter informaçoes do projeto  " + erro);
                });
        },

        carregarGrafico() {
            // GRAFICO Segmento ---------------

            let chart = am4core.create(this.$refs.segmento, am4charts.PieChart3D);
            chart.legend = new am4charts.Legend();
            chart.data = this.dadosGrafico.segmentos.map((item) => {
                //console.log(item);
                return {
                    segmento: item.label,
                    percentual: item.quantidade,
                };
            });



            chart.legend.position = "right";
            
            chart.responsive.enabled = true;
            chart.responsive.rules.push({
                relevant: function (target) {
                    if (target.pixelWidth <= 600) {
                        return true;
                    }
                    return false;
                },
                state: function (target, stateId) {
                    if (target instanceof am4charts.PieSeries) {
                        var state = target.states.create(stateId);

                        var labelState = target.labels.template.states.create(stateId);
                        labelState.properties.disabled = true;

                        var tickState = target.ticks.template.states.create(stateId);
                        tickState.properties.disabled = true;
                        return state;
                    }

                    return null;
                }
            });







            chart.innerRadius = 0;
            let series = chart.series.push(new am4charts.PieSeries3D());
            series.dataFields.value = "percentual";
            series.dataFields.category = "segmento";

            // this.chart = chart;

            // GRAFICO Funcionários ---------------

            let chart1 = am4core.create(
                this.$refs.funcionarios,
                am4charts.PieChart3D
            );
            chart1.legend = new am4charts.Legend();
            chart1.data = this.dadosGrafico.funcionarios.map((item) => {
                //console.log(item);
                return {
                    segmento: item.label,
                    percentual: item.quantidade,
                };
            });

            chart1.legend.position = "right";
            
            chart1.responsive.enabled = true;
            chart1.responsive.rules.push({
                relevant: function (target) {
                    if (target.pixelWidth <= 600) {
                        return true;
                    }
                    return false;
                },
                state: function (target, stateId) {
                    if (target instanceof am4charts.PieSeries) {
                        var state = target.states.create(stateId);

                        var labelState = target.labels.template.states.create(stateId);
                        labelState.properties.disabled = true;

                        var tickState = target.ticks.template.states.create(stateId);
                        tickState.properties.disabled = true;
                        return state;
                    }

                    return null;
                }
            });





            chart1.innerRadius = 0;
            let series1 = chart1.series.push(new am4charts.PieSeries3D());
            series1.dataFields.value = "percentual";
            series1.dataFields.category = "segmento";

            // this.chart = chart1;

            // GRAFICO Estados ---------------

            chart1 = am4core.create(this.$refs.estados, am4charts.PieChart3D);
            chart1.legend = new am4charts.Legend();
            chart1.data = this.dadosGrafico.estados.map((item) => {
                //console.log(item);
                return {
                    segmento: item.label,
                    percentual: item.quantidade,
                };
            });

            chart1.legend.position = "right";
            
            chart1.responsive.enabled = true;
            chart1.responsive.rules.push({
                relevant: function (target) {
                    if (target.pixelWidth <= 600) {
                        return true;
                    }
                    return false;
                },
                state: function (target, stateId) {
                    if (target instanceof am4charts.PieSeries) {
                        var state = target.states.create(stateId);

                        var labelState = target.labels.template.states.create(stateId);
                        labelState.properties.disabled = true;

                        var tickState = target.ticks.template.states.create(stateId);
                        tickState.properties.disabled = true;
                        return state;
                    }

                    return null;
                }
            });




            chart1.innerRadius = 0;
            series1 = chart1.series.push(new am4charts.PieSeries3D());
            series1.dataFields.value = "percentual";
            series1.dataFields.category = "segmento";

            // GRAFICO Faturamento ---------------

            chart1 = am4core.create(this.$refs.faturamento, am4charts.PieChart3D);
            chart1.legend = new am4charts.Legend();
            chart1.data = this.dadosGrafico.faturamento.map((item) => {
                //console.log(item);
                return {
                    segmento: item.label,
                    percentual: item.quantidade,
                };
            });

            chart1.legend.position = "right";
            
            chart1.responsive.enabled = true;
            chart1.responsive.rules.push({
                relevant: function (target) {
                    if (target.pixelWidth <= 600) {
                        return true;
                    }
                    return false;
                },
                state: function (target, stateId) {
                    if (target instanceof am4charts.PieSeries) {
                        var state = target.states.create(stateId);

                        var labelState = target.labels.template.states.create(stateId);
                        labelState.properties.disabled = true;

                        var tickState = target.ticks.template.states.create(stateId);
                        tickState.properties.disabled = true;
                        return state;
                    }

                    return null;
                }
            });



            chart1.innerRadius = 0;
            series1 = chart1.series.push(new am4charts.PieSeries3D());
            series1.dataFields.value = "percentual";
            series1.dataFields.category = "segmento";

        },

        pesquisar() {
            //alert()
            //this.dadosGrafico = []
            // this.dadosGrafico.segmentos = []
            // this.carregarGrafico()
            this.total = undefined;
            this.loading = true;
            let inicio = this.dateStart
                .split("/")
                .reverse()
                .join("-");
            let fim = this.endData
                .split("/")
                .reverse()
                .join("-");
            // console.log(inicio );
            // console.log(fim);

            projetoService
                .getInfDashboardFilter(this.$route.params.id, inicio, fim)
                .then((res) => {
                    // console.log(res);
                    this.dadosGrafico = res.data.empresas_qualificadas;
                    //console.log(this.dadosGrafico );
                    this.showAllPeriod = false;
                    this.total = res.data.empresas_qualificadas.total.toLocaleString(
                        "pt-BR"
                    );
                    this.carregarGrafico();
                    this.loading = false;
                })
                .catch((erro) => {
                    console.error(
                        "erro ao obter informaçoes filtrada do projeto  " + erro
                    );
                    this.loading = false;
                });
        },
        removeFilters() {
            //alert()
            this.total = undefined;
            // this.loadingRemove = true

            this.getInfDashboard();
            // this.loadingRemove = false
        },
    },
    mounted() {
        this.getInfDashboard();
        //this.carregarGrafico()
    },

    beforeDestroy() {
        if (this.chart) {
            this.chart.dispose();
        }
    },

    
};
</script>

<style lang="scss" scoped>
.select--periodo {
    max-width: 170px;
    cursor: pointer;
    padding-bottom: 14px;

    display: inline-block;
    color: #6c7293;
    transition: 0.1s;
}

.my-svg {
    width: 30px;
}

.spaceGrafico {
    height: 400px;

    width: 100%;
    min-width: 400px;
}
</style>