<template>
<section class="tables" id="TabelaTotalOportunidade">

    <div class="row">

        <div class="col-lg-12 grid-margin stretch-card">
            <div class="card">
                <div class="card-body">
                    <b-table   responsive :items="items"  > 
                    <!-- <b-table hover responsive v-for="(item, index) in items" v-bind:key="index"> -->

                        <template #cell(Detalhes)="data">
                            <!-- <b class="text-info"> {{data}} </b> -->
                            <button class="d-none">{{data}}</button>
                            <button class="btn btn-outline-primary px-4 py-1"><i class="mdi mdi-eye-outline text-primary mr-2"></i>Visualizar</button>
                        </template>



                    </b-table>
                </div>
            </div>
        </div>

    </div>
</section>
</template>

<script>

export default {
    name: 'basicTables',
    data: function() {
        return {
            //   itemsTwo: itemsTwo,
            items: [
                // { isActive: false, age: 21, first_name: 'Larsen', last_name: 'Shaw' },
                {
                    Segmento: 'Tecnologia',
                    Nº_Funcionário: "1 A 50",
                    Estado: "AM",
                    Porte: "Pequeno",
                    Faturamento: "Acima de 200",
                    Empresa: "Garcia S.A",
                    Motivo: "Budget",                    
                },
                {
                    Segmento: 'Varejo',
                    Nº_Funcionário: "200 A 500",
                    Estado: "PA",
                    Porte: "Média",
                    Faturamento: "Acima de 200",
                    Empresa: "Pablo LTDA",
                    Motivo: "Need",                    
                },

                {
                    Segmento: 'Tecnologia',
                    Nº_Funcionário: "500 A 1000",
                    Estado: "MG",
                    Porte: "Grande",
                    Faturamento: "Acima de 200",
                    Empresa: "Empada LTDA",
                    Motivo: "Refused",                    
                },
                                {
                    Segmento: 'Indústria',
                    Nº_Funcionário: "1000 A 5000",
                    Estado: "SP",
                    Porte: "Grande",
                    Faturamento: "Acima de 200",
                    Empresa: "MM & TS LDTA  ",
                    Motivo: "Timing",                    
                },


                /*
                { isActive: false, age: 21, first_name: 'Larsen', last_name: 'Shaw' },
                { isActive: false, age: 89, first_name: 'Geneva', last_name: 'Wilson' },
                { isActive: true, age: 38, first_name: 'Jami', last_name: 'Carney' }
                */
            ],
            fields: [{
                    key: 'first_name',
                    label: 'Person first name',
                    sortable: true
                },
                {
                    key: 'last_name',
                    label: 'Person last name',
                    sortable: true
                },
                {
                    key: 'age',
                    label: 'Person age',
                    sortable: true,
                    // Variant applies to the whole column, including the header and footer
                    variant: 'success'
                }
            ],
        }
    }
}
</script>


<style  >


#TabelaTotalOportunidade .table-hover tbody tr:hover{
    color: rgb(255, 255, 255) ;
}

</style>

