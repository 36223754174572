<template>
<section class="chartjs" id="GraficoMetaGeralProjeto">
    <div class="page-header align-items-start" style="align-items: flex-start !important">
        <!-- <h3 class="page-title">
            Meta Geral do Projeto <span class="mx-2"> |</span>

            <strong v-if="!total">Carregando ...</strong>
            <strong v-else>{{total}}</strong>
        </h3> -->

        <h3 class="page-title d-flex align-items-center">
            Meta Geral do Projeto <span class="mx-2"> |</span>
            <!-- <strong v-if="!total">Carregando ...</strong> -->
            <b-skeleton v-if="!total" class="d-inline-block " width="50px"></b-skeleton>

            <strong v-else>{{ total }}</strong> 
        </h3>



        <nav aria-label="breadcrumb">
            <ol class="breadcrumb pt-0 pb-0">
                <li class="breadcrumb-item">
                    <a href="javascript:void(0);" v-on:click="goBackVisaogeral">Visão Geral</a>
                </li>
                <!-- <li class="breadcrumb-item"><router-link to="/projeto/dashboard">Visão Geral</router-link></li> -->
                <li class="breadcrumb-item active" aria-current="page">
                    Meta Geral do Projeto
                </li>
            </ol>
        </nav>
    </div>

    <div class="row">
        <div class="col-lg-12 grid-margin stretch-card">
            <div class="card">
                <div class="card-body">

                    <h4 class="card-title visao">
                        Visão por
                        <span class="text-info">{{tipoVisao}} </span>
                    </h4>

                    <div>
                        <b-tabs content-class="mt-3" lazy   is-dark align="right">
                            <b-tab title="Mês" active  @click="tipoVisao = 'Mês'">
                    

                            <BarChart visao="mes"  />

                            </b-tab>

                            <b-tab title="Semana "  @click="tipoVisao = 'Semana'">


                          <BarChart visao="semana"  />



                            </b-tab>

                            <b-tab title="Dia" @click="tipoVisao = 'Dia'">

                            <BarChart visao="dia" />


                            </b-tab>
                        </b-tabs>
                    </div>

                </div>
            </div>
        </div>
    </div>

</section>
</template>

<script>
import BarChart from "./barChart.vue";
import projetoService from "../../../services/projeto.service";

export default {
    name: "chartjs",
    components: {
        BarChart,
    },

    data() {
        return {
            tipoVisao: "Mês",
            total: undefined,
        };
    },
    methods: {

        goBackVisaogeral() {
            document.getElementById('visaoGeralButton___BV_tab_button__').click()
        },
        getInfDashboard() {
            projetoService.getInfDashboard(this.$route.params.id)
                .then((res) => {
                  
                    this.total = res.data.metas.meta_geral.toLocaleString("pt-BR")
                })
                .catch((erro) => {
                    console.error("erro ao obter informaçoes do projeto  " + erro);
                })
        },

        
        

    },

    computed: {},
    watch:{
        
    },
    mounted() {
        this.getInfDashboard()    

        

    }

};
</script>

<style lang="scss" scoped>

</style><style lang="scss">
#GraficoMetaGeralProjeto {
    .nav-tabs .nav-item .nav-link {
        max-width: 90px;
        border-radius: 5px !important;
    }

    .nav-tabs .nav-link.active,
    .nav-tabs .nav-item.show .nav-link {
        color: #fff !important;
    }

    ul {
        position: absolute;
        top: 15px;
        right: 35px;
        border-bottom: none
    }

}
</style>
