<template>
<div id="projetoDashboard">

    <div class="page-header ">
        <h3 class="text-capitalize d-flex align-items-center page-title">
            <router-link to="/" style="color: inherit">
                <i class="mdi mdi-arrow-left mr-3"></i>
            </router-link>
            <!-- React Native -->
            <!-- <b-skeleton v-if="!projeto.nome" class="d-inline-block " width="50px"></b-skeleton> -->
            <b-skeleton  v-if="!projeto.nome"  class="d-inline-block " width="120px"></b-skeleton>

            <span v-else>{{projeto.nome}} </span> 
        </h3>

        <!-- <h3 class="page-title d-flex align-items-center">
            Base de Contatos <span class="mx-2"> |</span>
            <b-skeleton v-if="!total" class="d-inline-block " width="50px"></b-skeleton>

            <strong v-else>{{ total }}</strong>
        </h3> -->

        

    </div>

    <b-tabs pills card vertical >
        <b-tab active id="visaoGeralButton" >
            <template #title>
               <span class="text-muted">Visão Geral</span>  
            </template>

            <VisaoGeral />

        </b-tab>

        <b-tab v-on:click="handlerGrafico('Base de Empresas')">
             <template #title >
                <span class="text-muted" >Base de Empresas</span> 
            </template>

            <GraficoBaseEmpresas v-if="showGrafico.showBaseEmpresa" />

        </b-tab>

        <b-tab title="Base de Contatos" v-on:click="handlerGrafico('Base de Contatos')">

             <template #title >
                <span class="text-muted" >Base de Contatos</span> 
            </template>


            <GraficoBaseContatos v-if="showGrafico.showBaseDeContatos" />
        </b-tab>

        <b-tab title="Empresas Qualificadas" v-on:click="handlerGrafico('Empresas Qualificadas')">
             <template #title >
                <span class="text-muted">
                    Empresas Qualificadas
                </span>
             </template>
            <GraficoEmpresaQualificada v-if="showGrafico.showEmpresaQualificadas" />
        </b-tab>

        <b-tab title="Empresas Não Qualificadas" v-on:click="handlerGrafico('Empresas Não Qualificadas')">

             <template #title >
                <span class="text-muted">
                    Empresas Não Qualificadas
                </span>
             </template>

            <GraficoEmpresaNaoQualificada v-if="showGrafico.showEmpresaNaoQualificadas" />
        </b-tab>

        <b-tab title="Total de Oportunidades" v-on:click="handlerGrafico('Total de Oportunidades')">

             <template #title >
                <span class="text-muted">Total de Oportunidades</span>
             </template>

            <GraficoTotalOportunidades v-if="showGrafico.showTotalOportunidades" />
        </b-tab>
        <b-tab title="Total de Recusas" v-on:click="handlerGrafico('Total de Recusas')">
             <template #title >
                <span class="text-muted">Total de Recusas</span>
             </template>

            <GraficoTotalRecusas v-if="showGrafico.showTotalRecusas" />
        </b-tab>

        <b-tab title="Meta Geral do Projeto" v-on:click="handlerGrafico('Meta Geral do Projeto')">
             <template #title >
                <span class="text-muted">Meta Geral do Projeto</span>
             </template>

            <GraficoMetaGeralProjeto v-if="showGrafico.showMetaGeral" />
        </b-tab>

        <!-- <b-tab title="Teste" v-on:click="handlerGrafico('')">
            <GraficoTeste  />
        </b-tab> -->


    </b-tabs>

    <!-- <Aside/>  -->

</div>
</template>

<script>


import VisaoGeral from './partials/VisaoGeral.vue';

import GraficoBaseEmpresas from './partials/GraficoBaseEmpresas';
import GraficoBaseContatos from './partials/GraficoBaseContatos';
import GraficoEmpresaQualificada from './partials/GraficoEmpresaQualificada';
import GraficoEmpresaNaoQualificada from './partials/GraficoEmpresaNaoQualificada';
import GraficoTotalOportunidades from './partials/GraficoTotalOportunidades.vue';
import GraficoTotalRecusas from './partials/GraficoTotalRecusas.vue';
import GraficoMetaGeralProjeto from './partials/GraficoMetaGeralProjeto.vue';

import projetoService from '../../services/projeto.service';


// import GraficoTeste from './partials/GraficoTeste.vue';



export default {
    name: "dashboard",
    components: {
    
        GraficoBaseEmpresas,
        GraficoBaseContatos,
        GraficoEmpresaQualificada,
        GraficoEmpresaNaoQualificada,
        GraficoTotalOportunidades,
        GraficoTotalRecusas,
        GraficoMetaGeralProjeto,

        VisaoGeral,
        // GraficoTeste,

        
    },
    data() {
        return {
            time1: null,
            slide1: 0,
            // show: false,
            showGrafico:{
                showBaseEmpresa:false,
                showBaseDeContatos:false,
                showEmpresaQualificadas:false,
                showEmpresaNaoQualificadas:false,
                showTotalOportunidades:false,
                showTotalRecusas:false,
                showMetaGeral:false,
            },
            projeto: []
        };
    },

    methods: {

        handleSCroll() {
            let heade = document.querySelector("#projetoDashboard .col-auto ul");
            //  alert()
            // console.log(header);
            if (window.scrollY > 150) {
                heade.classList.add("setTop");
                //  console.log("limbo");
            } else {
                heade.classList.remove("setTop");
            }
        },

        handlerGrafico(value){            
            if(value === 'Base de Empresas'){
                this.showGrafico.showBaseEmpresa =true
            }
            if(value === 'Base de Contatos'){
                this.showGrafico.showBaseDeContatos =true
            }
            if(value === 'Empresas Qualificadas'){
                this.showGrafico.showEmpresaQualificadas =true
            }

            if(value === 'Empresas Não Qualificadas'){
                this.showGrafico.showEmpresaNaoQualificadas =true
            }

            if(value === 'Total de Oportunidades'){
                this.showGrafico.showTotalOportunidades =true
            }

            if(value === 'Total de Recusas'){
                this.showGrafico.showTotalRecusas =true
            }

            if(value === 'Meta Geral do Projeto'){
                this.showGrafico.showMetaGeral =true
            }                  
        },

        getInfProject(){
           // $route.params.id => id do projeto
           projetoService.getInfProject(this.$route.params.id)
           .then(res=>{
              // console.log(res.data);
               this.projeto = res.data
           })
           .catch(erro=>console.log("erro ao obter Informações do Projeto "+erro ))
        },
        

    },

    created() {
        window.addEventListener("scroll", this.handleSCroll);

    },
    destroyed() {
        window.removeEventListener("scroll", this.handleSCroll);
    },

    mounted(){
    // console.log(this.$route.params.id);

     this.getInfProject()
    //  this.getInfDashboard() 
     
    }
};
</script>

<style lang="scss" scoped>




.titulo {
    position: absolute;
    top: 10px;
    left: 8px;
    cursor: default;
}
</style>

<style lang="scss">



#projetoDashboard .col-auto {
    width: 20%;

    &>ul {
        /* padding: 0; */
        /* padding: 0 1.25rem; */
        /* position: fixed; */
        background-color: #191c24;
        /* border: 2px solid #191c24; */
        /* top: 161px; */
         /* transition: 0.1s !important; */
        

        /* height: 80vh !important; */
        width: 17%;
        width: 230px;
        width: 250px;

        
        min-width: 230px;
        /* padding: 0; */

        height: auto !important;
        padding: 20px;
        padding-left: 0;
        padding-top: 0;

        border-radius: 0.25rem !important;

        &>li {
            /* background-color: red !important; */
            /* position: relative !important; */
        }

        &>li:first-child {
            /* margin-top: 40px; */
        }
    }
}

#projetoDashboard .tab-content.col {
    /* background: fuchsia; */
    width: 80%;
    padding-top: 0;

    &>div {
        padding: 0;
    }
}

#projetoDashboard .nav-pills .nav-item .nav-link,
#projetoDashboard .nav-tabs .nav-item .nav-link {
    text-align: left;
    /* color: #6c7293; */

    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    white-space: nowrap;
    /* color: #6c7293; */
    border-radius: 0 100px 100px 0;
    -webkit-transition-duration: 0.45s;
    transition-duration: 0.45s;
    transition-property: color;
    -webkit-transition-property: color;
    cursor: pointer;

    padding: 0.5rem 1rem !important;
    padding: 10px !important;
    padding: 15px !important;
    /* background: #0f1015 !important; */

    &:hover {
        background: #0f1015;
        color: #fff;
    }
}

#projetoDashboard .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    /* active */
    background: #0f1015;
    color: #fff;
    border-left: 3px solid #0090e7;

    
}

#projetoDashboard .nav-pills .nav-link.active span{
    color: rgb(255, 255, 255) !important;
}

#projetoDashboard .setTop {
    /* margin-top: -100px; */
    position: fixed;

    top: 82px !important;
}

#projetoDashboard {
    section > div.row  div > div > div > div > div > svg > g > g:nth-child(2) > g:nth-child(2) > g > g:nth-child(3){
    display: none !important;
  }

}


 


</style>
