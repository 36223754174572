<template>
<section class="chartjs">
    <div class="page-header align-items-start" style="align-items: flex-start !important">
        <!-- <h3 class="page-title">
            Base de Contatos <span class="mx-2"> |</span>
            <strong v-if="!total">Carregando ...</strong>
            <strong v-else>{{total}}</strong>
        </h3> -->

        <h3 class="page-title d-flex align-items-center">
            Base de Contatos <span class="mx-2"> |</span> 
            <!-- <strong v-if="!total">Carregando ...</strong> -->
            <b-skeleton v-if="!total" class="d-inline-block " width="50px"></b-skeleton>

            <strong v-else>{{ total }}</strong>
        </h3>

        <b-form-checkbox  v-model="$store.getters.mudarVisualizacao" name="check-button" switch   @change="changeMudarVisualizacao">          
            <span >Mudar Visualização </span>      
        </b-form-checkbox>



        <nav aria-label="breadcrumb">
            <ol class="breadcrumb pt-0 pb-0">
                <li class="breadcrumb-item">
                    <a href="javascript:void(0);" v-on:click="goBackVisaogeral">Visão Geral</a>
                </li>
                <!-- <li class="breadcrumb-item"><router-link to="/projeto/dashboard">Visão Geral</router-link></li> -->
                <li class="breadcrumb-item active" aria-current="page">
                    Base de Contatos
                </li>
            </ol>
        </nav>
    </div>
    
    <div class="row" >
        <div :class="mudarVisualizacao ? 'col-lg-12 grid-margin stretch-card' : 'col-lg-6 grid-margin stretch-card' ">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Qualificados</h4>

                    <DoughnutChartAmcharts descricaoAba="base_contatos" tipo="qualificados" />
                </div>
            </div>
        </div>
        <div :class="mudarVisualizacao ? 'col-lg-12 grid-margin stretch-card' : 'col-lg-6 grid-margin stretch-card' ">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Não Qualificados</h4>

                    <DoughnutChartAmcharts descricaoAba="base_contatos" tipo="nao_qualificados" />
                </div>
            </div>
        </div>
    </div>


  


</section>
</template>

<script>
import DoughnutChartAmcharts from "./DoughnutChartAmcharts.vue";
import projetoService from "../../../services/projeto.service";
import { mapMutations, mapState } from 'vuex';


export default {
    name: "chartjs",
    components: {

        DoughnutChartAmcharts,
    },
    data() {
        return {

            total: undefined,
        }

    },

    methods: {
        ...mapMutations(["changeMudarVisualizacao"]),

        goBackVisaogeral() {
            document.getElementById('visaoGeralButton___BV_tab_button__').click()
        },
        getInfDashboard() {
            projetoService.getInfDashboard(this.$route.params.id)
                .then((res) => {
                    //console.log(res.data);
                    this.total = res.data.base_contatos.total.toLocaleString("pt-BR")
                })
                .catch((erro) => {
                    console.error("erro ao obter informaçoes do projeto  " + erro);
                })

        }
    },
    mounted() {
        this.getInfDashboard()


    },
    computed:{
        ...mapState(["mudarVisualizacao"])

    }

};
</script>
